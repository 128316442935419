import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContactAndAddressTile from "../components/contact-and-address-tile/contact-and-address-tile"
import SolidHero from "../components/solid-hero/solid-hero"

const KontaktPage = () => (
  <Layout>
    <SEO title={'Kontakt'} description={'Hin laufen – zurück fahren! Das Team unserer Fahrschule in Backnang freut sich auf dich. Jetzt direkt unverbindlich anfragen und bei Rauscher einsteigen.'}/>
    <SolidHero title={'Kontakt'} primary subtitle={() => <>Du hast Fragen zu deinem Führerschein?<br/>Dann schreib uns gerne eine Nachricht und wir melden uns schnellstmöglich bei dir.</>}></SolidHero>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KontaktPage
